.delivery-address-box {
    background-color: var(--ion-color-secondary);
    height: 44px;
    display: flex;
}

.delivery-address-box div#button {
    padding: 0;
    background-color: white;
    height: 44px;
    width: 100%;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    box-shadow: rgba(51, 51, 51, 0.1) 3px 0 3px 0;
    box-sizing: border-box;
}

.delivery-address-box .address-name {
    color: var(--ion-color-primary);
}

.delivery-address-box div#button ion-button {
    height: 100%;
    width: 100%;
    text-align: left;
}

.delivery-address-box div#button ion-button ion-text {
    width: 100%;
    padding: 0 20px;
    font-weight: 600;
    font-size: 14px;
}

.delivery-address-box div#button ion-button ion-icon {
    font-size: 26px;
}

.delivery-address-box div#house-icon {
    text-align: center;
    font-size: 30px;
    margin-top: 7px;
    width: 20%;
}

.delivery-address-box div#house-icon p, .delivery-address-box div#house-icon h6 {
    line-height: 0;
    font-weight: 600;
    color: var(--ion-color-primary);
}

.delivery-address-box div#house-icon p {
    font-size: 10px;
}

.delivery-address-box div#house-icon h6 {
    font-size: 18px;
}