#NewDetail ion-card.listing-container {
    border-radius: 0;
    margin: 15px 0;
}
#NewDetail .subheading {
    margin-left: 15px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: -10px;
    color: var(--ion-color-medium);
}