.AddAddress ion-searchbar input {
    font-weight: 500 !important;
    font-size: 14px;
    padding-left: 40px;
}

.AddAddress ion-searchbar ion-icon {
    /* --icon-color	 */
    color: var(--ion-color-primary);
    opacity: 1;
    --ionicon-stroke-width: 64px;

}


.AddAddress ion-content {
    --overflow: hidden;
}
.AddAddress ion-footer ion-button {
    font-size: 18px;
    font-weight: 400;
}
ion-toolbar.AddAddress  ion-row {
    padding-left: 10px;
    padding-right: 10px;
}

.AddAddress #help {
    width: 160px;
    height: 28px;
    background-color: white;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0; 
    z-index: 1;
    margin: auto;
    border-radius: 3px;
    box-shadow: 0 1px 2px 1px rgb(0 0 0 / 15%), 0 2px 5px -3px rgb(0 0 0 / 15%);
    /* border: 1px solid var(--ion-color-light); */
}



.leaflet-touch .leaflet-bar {
    border: 2px solid var(--ion-color-light);    
}

.AddAddress #help ion-icon {
    font-size: 22px;
    position: absolute;
    top: 3px;
    left: 5px;
}


.AddAddress #locate-button {
    position: absolute;
    bottom: 40px;
    z-index: 1222;
    right: 40px;
    height: 40px;
    width: 40px;
    border-radius: 100px;
    font-size: 26px !important;
    box-shadow: 0 1px 2px 1px rgb(0 0 0 / 15%), 0 2px 5px -3px rgb(0 0 0 / 15%);
    background-color: white;
    text-align: center;
    padding-top: 8px;
}

.AddAddress #help span {
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    left: 32px;
    top: 8px;
    color: var(--ion-color-medium);
}
