#GetirCartTool .card {
    height: 35px;
    width: 35px;
    padding: 0;
    box-shadow: rgb(88 66 181 / 15%) 0px 4px 16px;
    margin: 0;
    background-color: white;
    border: unset !important;
    z-index: 1;
    
    text-align: center;
}

#GetirCartTool .card ion-icon {
    font-size: 16px;
    --ionicon-stroke-width: 70px;
    margin-top: 10px;
}


#GetirCartTool .card.increase {
    position: absolute;
    right: 4px;
    top: 4px;
}


#GetirCartTool .card.count {
    position: absolute;
    right: 4px;
    top: 38px;
    /* height: 38px; */
    box-shadow: unset;
    background-color: var(--ion-color-primary);
    z-index: 2;
    border-radius: 0;
    text-align: center;
    padding-top: 10px;
}

#GetirCartTool .card.count span {
    color: white;
    font-weight: 600;
}

#GetirCartTool .card.decrease {
    position: absolute;
    right: 4px;
    top: 73px;
}