#tickets ion-menu-button {
    color: white !important;
}

#left-panel .listing-messages ion-row {
    padding: 5px;
}

#left-panel .listing-messages ion-card {
    display: flex;
    align-items: center;
    height: 60px !important;
}

#left-panel .listing-messages ion-row>ion-col {
    margin: 0 !important;
    padding: 3px 0 !important;
}

#left-panel .listing-messages ion-col>ion-card {
    padding: 0 5px;
    display: flex;
    flex-direction: row;
    box-shadow: none !important;
    border: none;
    border-radius: 0px !important;
    margin: 0 !important;
}

#left-panel .listing-messages ion-col>ion-card.active {
    background: #5d3ebc;
    color: white !important;
}

#left-panel .listing-messages .customer-service ion-thumbnail {
    border-radius: 50% !important;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 0;
    overflow: hidden;
}

#left-panel .listing-messages .customer-service ion-thumbnail ion-card {
    border-radius: 50% !important;
    overflow: hidden;
    border: 0 !important;
    width: 50px !important;
    background: none !important;
    height: 50px !important;
}

#left-panel .listing-messages .customer-service ion-label {
    width: 80% !important;
    margin: 0;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    align-items: flex-start;
    height: 50px;
    border-bottom: 1px solid rgb(211, 211, 211) !important;
}

#left-panel .listing-messages .customer-service.active ion-label {
    border-bottom: none !important;
}


#left-panel .listing-messages .customer-service ion-title {
    position: relative;
    width: auto;
    height: 20px !important;
    padding: 0 8px;
}

#left-panel .listing-messages .customer-service .name {
    font-size: 14px;
}

#left-panel .listing-messages .customer-service .last-messages {
    text-align: left;
    width: 100%;
    font-size: 10px;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

#left-panel .listing-messages .customer-service .date {
    width: 80px !important;
    position: fixed;
    left: 72%;
    top: 7px;
    font-size: 8px;
}




/* Messages Section */
#tickets :is(ion-header, ion-toolbar) {
    height: 78px;
}

/* css for user ballons */
#tickets .message-section ion-card {
    box-shadow: none !important;
    margin: 0px;
}

#tickets .message-section ion-col {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

#tickets .message-section .message-date {
    margin-top: 20px;
    box-shadow: none !important;
    background: none !important;
    border: 0 !important;
    font-size: 12px;
    text-align: center;
}


#tickets .message-section .message-ballon {
    position: relative;
    border: none !important;
    margin-left: auto;
    color: white;
    padding: 8px 50px 17px 10px;
    font-size: 12px;
    text-align: start;
    height: auto !important;
    width: auto !important;
    max-width: 70%;
    background: #5d3ebc;
}

#tickets .message-section ion-col:last-child .message-ballon {
    animation: toLeft 0.2s forwards ease;

}

@keyframes toLeft {
    0% {
        visibility: hidden;
        transform: translateX(50px);
    }

    100% {
        visibility: visible;
        transform: translateX(0);
    }
}

#tickets .message-section .message-ballon .message-ballon-date {
    display: flex !important;
    position: absolute !important;
    font-size: 8px;
    bottom: 4px !important;
    right: 5px !important;
    width: auto;
    padding: 0;

}

#tickets .message-section .user-row ion-col:first-child .message-ballon {
    border-radius: 8px 8px 0px 8px;
}

#tickets .message-section .user-row ion-col:not(:is(:last-child, :first-child)) .message-ballon {
    border-radius: 8px 0px 0px 8px;
}

#tickets .message-section .user-row ion-col:last-child .message-ballon {
    border-radius: 8px 0px 8px 8px;
}

#tickets .message-section ion-item {
    --inner-border-width: 0;
}

/* css for service ballons */
#tickets .message-section .service-row .message-ballon {
    margin-right: auto !important;
    margin-left: 0;
    color: white;
    padding: 15px 20px;
    font-size: 12px;
    border-radius: 8px 8px 8px 0px;
}

#tickets .message-section .service-row ion-col:first-child .message-ballon {
    border-radius: 8px 8px 8px 0px;
}

#tickets .message-section .service-row ion-col:not(:is(:last-child, :first-child)) .message-ballon {
    border-radius: 0px 8px 8px 0px;
}

#tickets .message-section .service-row ion-col:last-child .message-ballon {
    border-radius: 0px 8px 8px 8px;
}

#tickets .message-section {
    --padding-end: 10px !important;
}

/* attachment */

#tickets .message-section {
    --padding-bottom: 60px !important;
    --padding-end: 0 !important;
}

#tickets .attachment {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    height: 60px;
    left: 0;
    width: 100%;
    padding: 5px 15px !important;
    --inner-border-width: 0;
    background: #fff !important;
    margin: 0;
    border-radius: 0 !important;
}

#tickets .attachment ion-input {
    height: 40px;
    padding: 0 15px !important;
    background: #f0f0f0;
    font-size: 12px;
    border-radius: 10px !important;
    margin: 0 15px;
}

#tickets .attachment .attach-file {
    --background: #f0f0f0 !important;
    color: #939393;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50% !important;
    font-size: 20px;
    overflow: hidden;
}

#tickets .attachment .attach-file:hover {
    color: white;
}

#tickets .attachment input[type="file"] {
    display: none;
}

#left-panel .listing-messages ion-button.new-ticket {
    --border-radius: 20px !important;
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    height: 75px !important;
    width: 160px !important;
    padding: 20px 13px;
    position: fixed;
    right: 10px !important;
    bottom: 10px;
}

#left-panel .listing-messages .new-ticket ion-icon {
    font-size: 18px;
}

#left-panel .listing-messages .new-ticket ion-title {
    position: relative;
    padding: 0 !important;
    margin-left: 5px;
    width: auto;
    font-weight: 500 !important;
}


/* modal css */
#new-ticket-modal .new-ticket-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70% !important;
    padding: 20px 30px;
    height: auto;
}

#new-ticket-modal .new-ticket-card input {
    border-radius: 0 !important;
}

#new-ticket-modal .new-ticket-card :is(ion-input, ion-textarea) {
    background: #eee;
    padding: 5px 15px !important;
    margin: 5px 0;
    border-radius: 8px;
}

#new-ticket-modal .new-ticket-card ion-textarea textarea {
    height: 70px !important;
}

#new-ticket-modal .new-ticket-card ion-button {
    margin: 5px 0 !important;
    --padding-start: 1.5em;
    --padding-end: 1.5em;
}

#tickets .message-section .new-ticket {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    --border-radius: 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px !important;
    width: 180px !important;
    padding: 20px 13px;
}

#tickets .message-section .new-ticket ion-title {
    position: relative;
    padding: 0 !important;
    width: auto;
}