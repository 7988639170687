#ProductDetail ion-card.listing-container {
    padding-bottom: 200px;
    border-radius: 0;
    margin: 15px 0;
}

#ProductDetail .subheading {
    margin-left: 15px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: -10px;
    color: var(--ion-color-medium);
}

.ProductDetail__main-product-detail-container {
    text-align: center;
    background-color: white;
    padding-top: 15px;
    padding-bottom: 15px;
}

#ProductDetail .product-price {
    line-height: 1;
    font-size: 14px;
    font-weight: 700;
}

#ProductDetail .product-price s {
    color: var(--ion-color-medium);
}

#ProductDetail .product-price span {
    color: var(--ion-color-primary);
}

#ProductDetail .product-title {
    font-size: 16px;
    color: var(--ion-color-dark);
    font-weight: 600;
}

#ProductDetail .product-sub-title {
    font-size: 12px;
    color: var(--ion-color-medium);
    font-weight: 600;
}

#ProductDetail ion-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

#ProductDetail ion-footer>ion-card {
    width: 100%;
    height: 100px;
    margin: 0;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

#ProductDetail ion-footer ion-button {
    font-size: 20px;
    margin: 0 auto;
    width: 95%;
    height: 60px !important;
}

#ProductDetail #GetirCartTool {
    border-radius: 6px;
    display: flex !important;
    flex-direction: row-reverse !important;
    box-shadow: 0 0.4rem 1rem rgba(126, 0, 216, 0.097);
}

#ProductDetail #GetirCartTool ion-card {
    width: 80px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    position: relative;
    left: 0;
    top: 0;
    right: 0;
    font-size: 20px;
}

/* #ProductDetail #GetirCartTool ion-card.increase {
    border-radius: 0 10px 10px 0 !important;
}

#ProductDetail #GetirCartTool ion-card.decrease {
    border-radius: 10px 0 0 10px !important;
} */

#ProductDetail #GetirCartTool ion-card ion-icon {
    margin: 0;
    font-size: 20px;
}