
  .swiper-pagination-bullet-active {
    color: #fff;
    background: var(--ion-color-primary);
  }
  
  .swiper-pagination {
    text-align: right;
  }

  