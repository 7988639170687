#DeliveryCostModal ion-icon {
    font-size: 150px;
}

#DeliveryCostModal ion-item {
    --padding-bottom: 10px;
   --padding-start: 20px;
    --padding-end: 10px;
    --padding-top: 10px;
    margin-bottom: 1px;
    --background: var(--ion-color-light);
}
#DeliveryCostModal ion-label {
    font-size: 16px;
    font-weight: 600;
}