#CheckoutButton {
    box-shadow: rgb(93 62 188 / 4%) 0px 6px 24px !important;
}


#CheckoutButton ion-toolbar {
    --background	: #f5f5f5
}

#CheckoutButton .submit-button {
    height: 100%;
}

#CheckoutButton .total-cart {
    font-size: 18px;
    font-weight: 600;
}

