#Profile ion-card {
    border-radius: 0;
    margin: 0;
    font-weight: 500;
    
}


#Profile ion-card.buttons-card {
    margin-top: 15px;
    
}

#Profile ion-card.login-button-card {
    margin-top: 15px;
    
}

#Profile ion-card-content {
    padding-top: 0;
    padding-bottom: 0;
}
#Profile ion-item {
    --padding-start: 0;
    font-size: 14px;
}
#Profile .phone-number {
    font-size: 12px;
}
#Profile ion-label p {
    color: black;
}
