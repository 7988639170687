#Landing #search-button {
    border-radius: 10px;
    border: 1px solid var(--ion-color-primary);
    height: 46px;
    background-color: white;
    color: var(--ion-color-medium);
}
#Landing ion-toolbar {
    padding: 0;
}
#Landing #search-button span {
    font-size: 14px;
}

#Landing #search-button ion-icon {
    font-size: 24px;
    margin-top: 10px;
    margin-left: 10px;
    color: var(--ion-color-primary);
    --ionicon-stroke-width: 64px;
}

#Landing h4 {
    font-weight: 600;
}

#Landing #gates {
    height: 17%;
}

#Landing #gates ion-col {
    padding: 0;
    height: 100px;
}

#Landing .gate-box {
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 5px;
    background-color: white;
    height: 100%;
    cursor: pointer;
}
