.btn-app {
    --color-selected: #5d3ebc;
    --background	: #f5f5f5;
  }
  .btn-color {
    border: 0;
    padding-bottom: 15px;
  }
  .search-btn {
    margin-right: 30px;
  }
  
  .profile-btn {
    margin-left: 30px;
  }
ion-tab-button ion-icon {
    font-size: 24px;
}
.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: -2222;
}
  .fab-btn {
    --background: #5d3ebc;
    --border-color: white;
    --border-width: 2px;
    --border-style: solid;
    
  }
  .fab-icon {
    color: #ffd300;
    font-size: 24px;
}
  
.load .loading-content{
  background: 'none';
  width: 50px;
}

.toaster {
  color: var(--ion-color-primary);
  font-weight: 500;
  --border-radius: 5px;
  --background: white;
}

ion-title {
  font-size: 14px;
}

.logo {
  font-size: 22px;
}

ion-toolbar ion-icon {
  font-size: 20px !important;
}

ion-item {
  --border-color: #f3f0fe;
}

ion-card {
  border: 1px solid var(--ion-color-light);
  box-shadow: rgba(93, 62, 188, 0.1) 0px 6px 24px !important;
}

.fab-vertical-bottom {
  bottom: 25px !important;
}

ion-loading#with-bg {
  background: url(assets/images/bg.png);
}

.load .loading-wrapper {
  background: transparent;
}

.load .loading-content{
  background: 'none';
  width: 100px;
  
}

ion-footer ion-button {
  min-height: 50px;
  --border-radius: 8px !important;
}

ion-loading img {
  -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}