#GetirHome .category-card {
    margin: 5px 15px;
}

#GetirHome .category-title {
    margin: 5px -2px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    color: var(--ion-color-medium)
}

#GetirHome ion-accordion ion-label {
    font-size: 14px;
}

#GetirHome ion-accordion ion-item {
    --padding-top: 8px;
    --padding-bottom: 8px;
}

#GetirHome div#order-count {
    margin-right: 15px;
    border-radius: 50px;
    font-weight: 600;
    width: 30px;
    height: 30px;
    text-align: center;
    background-color: white;
    color: var(--ion-color-primary);
    box-shadow: rgb(93 62 188 / 4%) 0px 6px 24px !important;
}

#GetirHome div#order-count div {
    margin-top: 5px;
}

#GetirHome div#order-count ion-icon {
    font-size: 30px;   
}
#GetirHome ion-accordion ion-list ion-text {
    font-weight: 600;
}
#GetirHome ion-accordion ion-list p ion-text {
    font-weight: 500;
}
#GetirHome ion-accordion ion-list ion-button {
    height: 40px;
    --padding-end: 10px;
    --padding-start: 10px;
    margin-right: 5px;
    --border-radius: 10px;
    font-weight: 600;
}

