#Orders ion-card.listing-container {
    border-radius: 0;
    margin: 15px 0;
}

#Orders ion-card.listing-container ion-item {
    --padding-top: 5px;
    --padding-bottom: 5px;
}


#Orders ion-card.listing-container ion-label {
    font-size: 14px;
    margin: 0;
    font-weight: 600;
}


#Orders ion-card.listing-container .price-button ion-icon {
    background-color: white;
    border-radius: 10px;
}

