#GetirProducts ion-segment#category-segment {
    --background: #7849F7;
    height: 50px;
    
}

#GetirProducts ion-segment#category-segment span {
    color: white;
    text-transform: capitalize
}

#GetirProducts ion-segment#sub-category-segment span {
    color: var(--ion-color-primary);
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 600;
    margin: 0 !important;
    line-height: 0;
    letter-spacing: 0;
}

#GetirProducts ion-segment#sub-category-segment {
    --background: white;
    box-shadow: 5px;
}

#GetirProducts ion-segment#sub-category-segment ion-segment-button {
    margin: 8px;
    background-color: white;
    border: 1px solid #F9F8FC;
    border-radius: 5px;
    min-height: 30px !important;
    --indicator-color: white;
    
}


#GetirProducts ion-segment#sub-category-segment ion-segment-button.active {
    background-color: var(--ion-color-primary);
    border: 1px solid var(--ion-color-primary);
    color: white;
}

#GetirProducts ion-segment#sub-category-segment ion-segment-button.active span {
    color: white;
}


#GetirProducts ion-toolbar#sub-category-toolbar {
    box-shadow: rgb(0 0 0 / 5%) 0px 4px 6px;
    --background: white;
}

#GetirProducts #GetirProductList ion-card.products-card {
    border-radius: 0;
    background-color: white;
}

#GetirProducts #GetirProductList h6.sub-category-name {
    line-height: 0;
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--ion-color-medium);
    font-size: 14px;
    font-weight: 600;
}

