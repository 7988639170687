

.rccs__card--unknown > div {
    background: linear-gradient(90deg, rgba(77,58,159,1) 0%, rgba(93,62,188,1) 35%, rgba(105,85,188,1) 100%) !important;
  }
  
  .rccs__card--front, .rccs__card--back {
    background: linear-gradient(90deg, rgba(77,58,159,1) 0%, rgba(93,62,188,1) 35%, rgba(105,85,188,1) 100%) !important;
  }
  
  .secondary-input {
    width: 100%;
    padding: 12px 20px;
    display: inline-block;
    color: black;
    border: 0 solid var(--ion-color-medium);
    border-radius: 10px;
    background-color: white;
    box-sizing: border-box;
    border: 1px solid var(--ion-color-light);
    font-size: 14px;
    font-weight: 500;
  }
  
  .secondary-input:focus {
    outline: none !important;
    border: 1px solid var(--ion-color-primary);
    box-shadow: 0 0 10px var(--ion-color-primary-tint);
  }
  