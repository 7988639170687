#CartButton {
    background-color: #f3f0fe;
    border-radius: 5px !important;
    min-width: 94px;
    cursor: pointer;
    border: 0  !important;
    margin-right: 5px;
    height: 35px;
    padding-right: 5px;
}

#CartButton .price-col span {
    color: var(--ion-color-primary);
    font-size: 18px;
    font-weight: 900;
    letter-spacing: -0.5px;
}

#CartButton .price-col {
    padding: 0;
    padding-top: 7px;
    
}

#CartButton ion-icon {
    margin-top: 7px;
    padding: 0 5px;
}

#CartButton ion-icon.icon-container{
    background-color: #fff;
    font-size: 26px !important;
    
}


#CartButton ion-col{
    padding: 0
}