#Login .social-buttons ion-icon {
    font-size: 24px;
}
#Login .social-buttons {
    margin-top: 5px;
}
#Login ion-button {
    height: 50px;
}
#Login .register-text {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--ion-color-medium);
}
#Login .register-link {
    color: var(--ion-color-primary);
    cursor: pointer;
}