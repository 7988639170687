#EditOrderNotes .add-note {
    height: 100px !important;
    padding: 8px 10px !important;
    border-bottom: 1px solid var(--ion-color-light);
}

#EditOrderNotes .add-note textarea {
    height: 100px !important;
}

#EditOrderNotes .border-end {
    border-right: 1px solid var(--ion-color-light);
}

#EditOrderNotes .ion-row-custom {
    padding: 0 5px !important;
}

#EditOrderNotes .ion-row-custom ion-col {
    display: flex;
    justify-content: center;

}

#EditOrderNotes .ion-row-custom ion-button {
    width: 95% !important;
    --border-radius: 6px !important;
    height: 43px !important;
    margin: auto;
}

#EditOrderNotes .ion-row-custom ion-button.secondary-custom {
    --background: #5f6f83 !important;
}

#EditOrderNotes .checkout-button {
    font-size: 12px;
    font-weight: 500;
}



#EditOrderNotes ion-card {
    margin: 5px;
}
