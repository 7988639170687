/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
@font-face {
  font-family: "Cocon";
  font-style: normal;
  font-weight: bold;
  src: url("../assets/fonts/Cocon-Regular-Font.otf");
}


@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

html {
  font-family: 'Roboto', sans-serif;
}

.logo {
  font-family: "Cocon", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

ion-button {
  --border-radius: 5px;
  font-size: 14px;
}

body {
  --ion-background-color: rgb(250, 250, 250);
}

a {
  text-decoration: none;
}

.getir-alert .alert-message {
  font-size: 14px !important;
  font-weight: 500;
}

.getir-alert ion-icon {
  font-size: 40px;
}

.getir-alert .alert-button {
  border: unset;
  background-color: var(--ion-color-primary);
  margin: 0 20px 20px 20px;
  border-radius: 5px;
  color: white;
  font-weight: 500 !important;
}

.getir-alert .alert-button.ion-activated {
  background-color: var(--ion-color-primary-tint) !important;
}

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #5d3ebc;
  --ion-color-primary-rgb: 88, 66, 181;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #4d3a9f;
  --ion-color-primary-tint: #6955bc;

  /** secondary **/
  --ion-color-secondary: #ffd300;
  --ion-color-secondary-rgb: 246, 210, 71;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #d8b93e;
  --ion-color-secondary-tint: #f7d759;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #697488;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f3f0fe;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-default-font: 'Roboto', sans-serif !important;

  --ion-safe-area-top	: 20px;
}



ion-button {
  margin: 0;
}