#OrderDetail ion-card.listing-container {
    border-radius: 0;
    margin: 15px 0;
}

#OrderDetail ion-card.listing-container ion-item {
    --padding-top: 5px;
    --padding-bottom: 5px;
    --padding-start: 15px;
}


#OrderDetail ion-card.listing-container ion-label {
    font-size: 14px;
    margin: 0;
    font-weight: 500;
}
#OrderDetail .qty-span {
    font-size: 16px;
    font-weight: 600;
    color: var(--ion-color-primary);
}

#OrderDetail ion-card.listing-container ion-icon.cart-icon {
    background-color: white;
    border-radius: 10px;
    font-size: 40px;
    margin-right: 10px;
}

#OrderDetail .subheading {
    margin-left: 15px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: -10px;
    color: var(--ion-color-medium);
}