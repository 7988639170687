#Checkout ion-card.checkout-card {
    border-radius: 0;
    margin: 0;
    font-weight: 500;
    
}

#Checkout ion-item {
    --padding-start: 15px;
}

#Checkout ion-input.note-input {
    --padding-start: 15px !important;
}

#Checkout .subheading {
    padding-left: 15px;
    font-size: 14px;
    font-weight: 500;
    color: var(--ion-color-medium);
    margin-bottom: 5px;
}

#Checkout .checkout-button{
    --padding-start: 15px;
    --padding-end: 0;
    font-size: 12px;
}

#Checkout .payment-method-icon {
    margin-right: 10px;
    color: var(--ion-color-primary)
}
#Checkout .payment-detail ion-item {
    --padding-start: 15px;
    margin-right: 15px;
}

#Checkout .payment-detail ion-label {
    font-size: 14px;
    font-weight: 600;
}
#Checkout .payment-detail ion-label:nth-child(2) {
    font-weight: 600;
}

#Checkout .delivery-cost-alert {
    margin: 10px;
}

#Checkout .total-item ion-label {
    font-size: 18px;
    color: var(--ion-color-primary)
}

#Checkout .terms-item ion-label {
    font-size: 14px;
    font-weight: 500 !important;
}

#Checkout ion-checkbox, #Checkout ion-label {
    opacity: 1 !important;
}