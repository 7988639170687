#Textarea{
    border: 1px solid #f3f0fe;
    border-radius: 5px;
    background-color: white;
}

#Textarea ion-label {
    padding-left: 10px;
    font-weight: 600;
    font-size: 12px;
    color: var(--ion-color-primary);
}

#Textarea ion-Textarea {
    margin-top: 5px;
    --padding-start: 10px;
    --padding-top: 0;
    font-weight: 500;
    font-size: 14px;
    
}