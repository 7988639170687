#Track .toolbar-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

#Track .toolbar-container>ion-card {
    background-color: white;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
    padding-top: 10px;
}

#Track .toolbar-container .point {
    background-color: white;
    width: 10px;
    border-radius: 15px;
    height: 10px;
    border: 1px solid #DBDBFF;
    margin: auto;
    margin-bottom: 5px;
    z-index: 1;
}

#Track .toolbar-container .point.active {
    background-color: var(--ion-color-primary);
    width: 12px;
    border-radius: 15px;
    height: 12px;
    border: 1px solid var(--ion-color-primary);;
}

#Track .toolbar-container .profile-card {
    margin: 10px;
    padding: 5px;
    border: 1px solid var(--ion-color-light);
}

#Track .toolbar-container ion-button {
    height: 40px;
    width: 40px;
    --border-radius: 40px;
}


#Track .toolbar-container .profile-card ion-label {
    margin-left: 10px;
    color: black;
    font-weight: 500;
}

#Track .toolbar-container .line {
    width: 71px;
    height: 1px;
    background-color: var(--ion-color-light);;
    position: absolute;
    left: 38px;
    top: 5px;
    z-index: -1;
}

#Track .toolbar-container .state {
    letter-spacing: -0.6px;
    font-weight: 600;
    font-size: 14px;
}
#Track .toolbar-container .state.active {
    color: var(--ion-color-primary);
}
#Track ion-content {
    --overflow: hidden;
}