#Cart ion-card.square-card {
    margin: 0;
    border-radius: 0;
    background-color: white;
}

#Cart ion-item {

    --background: white;
}
